import { FC, useEffect, useMemo } from 'react';
import { Image } from '@storybook';

import { QrLoader } from '../qr-loader';
import { QrSuccessFooter, QrSuccessHeader } from '../../constant';
import { useKycQr } from '../../stores';

import './qr-success.scss';

interface IQrSuccess {
	status?: 'close' | 'redirect' | 'reload' | 'in-process' | 'terminated';
}

export const QrSuccess: FC<IQrSuccess> = ({ status = 'close' }) => {
	// Extract title and description based on redirect state, only recompute when redirect changes
	const { title, description, image } = useMemo(
		() => QrSuccessHeader[status],
		[status]
	);

	const { handleRedirectToNext } = useKycQr();

	// Initiates redirection process with a timeout when redirect is true
	useEffect(() => {
		if (status === 'redirect') {
			setTimeout(() => handleRedirectToNext(), 5000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="Qr__Success--wrapper">
			{/* Success icon section with a success animation image */}
			<div className="Qr__Success--icon-wrapper">
				<Image fileName={image} width="200" />
			</div>

			{/* Title and description section, displayed based on redirect state */}
			<div className="Qr__Success--details">
				<div className="Qr__Success--details_title">{title}</div>
				<div className="Qr__Success--details_description">{description}</div>
			</div>

			{/* Footer section displaying a loader or close button depending on redirect state */}
			<div className="Qr__Success--footer">
				{status === 'close' ? (
					<></>
				) : status === 'redirect' ? (
					<QrLoader description={QrSuccessFooter.MESSAGE} />
				) : (
					<></>
				)}
			</div>
		</div>
	);
};
