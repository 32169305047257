import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { BodyNameKeyType } from 'states';

import {
	selectedDocTypeState,
	vouchedCameraOpenState,
	ShowKycSelectCountry,
} from './store';
import { IndentityVerificationObject } from 'views/kyc/constants';
import {
	ChooseVerification,
	DocumentVerification,
	KycSelectCountry,
} from './components';
import {
	KycQrPage,
	KycQrPageNavigateState,
	KycQrProcessState,
	QrSuccess,
	ShowKycQrPageState,
} from '../kyc-qr-page';
import { isDesktopDevice } from 'utils/device';
import { useNextStep, useSharedVariables } from 'hooks';
import { Loader } from '@storybook';

export const DocumentVerificationWrapper = () => {
	const [bodyName, setBodyName] = useRecoilState(selectedDocTypeState);
	const setOpenVouchedCamera = useSetRecoilState(vouchedCameraOpenState);
	const showKycSelectCountry = useRecoilValue(ShowKycSelectCountry);
	// Recoil state to control the visibility of the KYC QR page.
	// `showKycQrPage` will be true when the KYC QR page should be displayed.
	const showKycQrPage = useRecoilValue(ShowKycQrPageState);

	// Recoil state to manage the current KYC QR process status and data.
	// `kycQrProcess` holds information related to the ongoing KYC QR flow.
	const [kycQrProcess] = useRecoilState(KycQrProcessState);

	// Recoil state for handling navigation within the KYC QR page.
	// `qrNavigate` stores the current navigation state for guiding users through the KYC steps.
	const [qrNavigate] = useRecoilState(KycQrPageNavigateState);

	// Access shared variables related to KYC QR processing.
	// `getKycQr` is likely a boolean or function indicating if the KYC QR flow should be initiated.
	const { getKycQr } = useSharedVariables();

	// Retrieve the payload detail for the next step in the KYC process.
	// `sessionPayloadDetail` contains data related to the next action in the KYC flow.
	const { sessionPayloadDetail } = useNextStep();

	// Use `useMemo` to memoize the `key` value from `sessionPayloadDetail.currentAction`.
	// This avoids unnecessary recalculations when `sessionPayloadDetail.currentAction` changes.
	const { key } = useMemo(
		() => sessionPayloadDetail.currentAction ?? {},
		[sessionPayloadDetail.currentAction]
	);

	const handleOnClickId = useCallback(
		(key: BodyNameKeyType) => {
			setBodyName(key);
			if (key === IndentityVerificationObject[1]?.key) {
				setOpenVouchedCamera(pre => ({ ...pre, docType: true }));
			} else {
				setOpenVouchedCamera(pre => ({ ...pre, docType: false }));
			}
		},
		[setBodyName, setOpenVouchedCamera]
	);

	const getComponent = useMemo(() => {
		if (getKycQr) {
			// If key is not 'allId', show success immediately
			if (key !== 'allId') {
				return <QrSuccess />;
			}

			// If kycQrProcess status is not available yet, show loading
			if (!kycQrProcess?.status) {
				return (
					<div className="loading-steps">
						<Loader />
					</div>
				);
			}

			// If qrNavigate is in specific states, show the KycQrPage
			if (
				qrNavigate === 'terminated' ||
				qrNavigate === 'in-process' ||
				qrNavigate === 'qr-success' ||
				qrNavigate === 'qr-success-reload'
			) {
				return <KycQrPage />;
			}
		}

		// If on desktop and showKycQrPage is true, show KycQrPage
		if (isDesktopDevice() && showKycQrPage) {
			return <KycQrPage />;
		}

		if (!bodyName && showKycSelectCountry) {
			return <KycSelectCountry />;
		} else if (!bodyName) {
			return <ChooseVerification handleOnClickId={handleOnClickId} />;
		} else {
			return (
				<DocumentVerification bodyName={bodyName} setBodyName={setBodyName} />
			);
		}
	}, [
		getKycQr,
		bodyName,
		showKycSelectCountry,
		key,
		kycQrProcess?.status,
		qrNavigate,
		showKycQrPage,
		handleOnClickId,
		setBodyName,
	]);

	return getComponent;
};
