import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { KycQr, QrLoader, QrSuccess } from './components';
import { QrLoading } from './constant';
import { IKycQrPageNavigate, KycQrPageNavigateState } from './stores';

export interface IKycQrPage {
	navigatePage?: IKycQrPageNavigate;
}

export const KycQrPage: FC<IKycQrPage> = () => {
	// Retrieve the current page navigation state for the QR flow
	const qrNavigate = useRecoilValue(KycQrPageNavigateState);

	// Memoize the component rendering logic based on `qrNavigate` state
	const renderKycQrPage = useMemo(() => {
		switch (qrNavigate) {
			// Display the main QR page component
			case 'qr-page':
				return <KycQr />;

			// Display success page with default success status
			case 'qr-success':
				return <QrSuccess />;

			// Display success page with redirect status
			case 'qr-success-next':
				return <QrSuccess status="redirect" />;

			// Display success page with reload status
			case 'qr-success-reload':
				return <QrSuccess status="reload" />;

			// Display loading screen with a loading message
			case 'qr-loader':
				return (
					<div className="loading-steps">
						<QrLoader description={QrLoading.MESSAGE} />
					</div>
				);

			// Display success page with in-process status
			case 'in-process':
				return <QrSuccess status="in-process" />;

			// Display success page with terminated status
			case 'terminated':
				return <QrSuccess status="terminated" />;

			// Default case: show the main QR page if no matching case is found
			default:
				return <KycQr />;
		}
	}, [qrNavigate]);

	// Render the appropriate component based on the `qrNavigate` state
	return renderKycQrPage;
};
