import { useCallback, useMemo, useEffect } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import { io } from 'socket.io-client';
import { v4 } from 'uuid';

import {
	QRKycListeners,
	QrKycEvents,
	QrKycStatus,
	QrKycUser,
	SimpliciLogo2,
} from '../constant';
import { useNextStep, useNotification, useSharedVariables } from 'hooks';
import {
	IKycQrProcessState,
	KycQrPageNavigateState,
	KycQrProcessState,
	ShowKycQrPageState,
} from './states';
import { REACT_APP_WEB_SOCKET as SERVER_URL } from 'envs';

// Custom hook to manage WebSocket connections for KYC (Know Your Customer) process
export const useWebSocket = () => {
	// Recoil state management to hold KYC QR process state and navigation
	const [kycQrProcess, setKycQrProcess] = useRecoilState(KycQrProcessState);
	const resetKycQrProcess = useResetRecoilState(KycQrProcessState);
	const setQrNavigate = useSetRecoilState(KycQrPageNavigateState);

	// Shared variables that provide session details
	const { getKycQr, connectionId } = useSharedVariables();

	// Memoized connection type based on whether `getKycQr` exists
	const getConnectionType = useMemo(
		() => (getKycQr ? QrKycUser.Secondary : QrKycUser.Primary),
		[getKycQr]
	);

	// Memoized connection ID, either from shared state or generating a new one
	const getConnectionId = useMemo(
		() => (getKycQr ? connectionId : v4()), // `v4()` generates a unique UUID
		[connectionId, getKycQr]
	);

	// Effect to update navigation based on the current KYC QR process status
	useEffect(() => {
		// Check if there's a status in the KYC process
		if (kycQrProcess?.status) {
			const { status } = kycQrProcess ?? {};

			// If the current user is the Primary device
			if (getConnectionType === QrKycUser.Primary) {
				if (status === QrKycStatus.SECONDARY_CONNECTED) {
					setQrNavigate('qr-loader'); // Navigate to QR loader if secondary is connected
				} else if (status === QrKycStatus.KYC_COMPLETE) {
					setQrNavigate('qr-success-next'); // Navigate to success screen if KYC is complete
				} else if (status === QrKycStatus.SECONDARY_DISCONNECTED) {
					setQrNavigate('qr-page'); // Navigate to QR page if secondary is disconnected
				}
			} else {
				// If the current user is the Secondary device
				if (status === QrKycStatus.QR_SESSION_FULL) {
					setQrNavigate('in-process'); // Navigate to in-process screen if session is full
				} else if (status === QrKycStatus.TERMINATED) {
					setQrNavigate('terminated'); // Navigate to terminated screen if session is terminated
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [kycQrProcess?.status]);

	// Callback to handle incoming data from the WebSocket listeners and update KYC process state
	const handleListeners = useCallback(
		(data: IKycQrProcessState) => {
			const { role, connectionId, message, status, nodes } = data ?? {};
			// Update the KYC QR process state based on the incoming data
			setKycQrProcess(prev => ({
				...prev,
				message,
				...(nodes && { nodes }),
				...(connectionId && { connectionId }),
				...(status && { status }),
				...(role && { role }),
			}));
		},
		[setKycQrProcess]
	);

	// Callback to establish the WebSocket connection and listen for events
	const connectToWebSocket = useCallback(() => {
		// Initializes a Socket.IO connection to SERVER_URL, using WebSocket or HTTP polling as fallback, enabling bidirectional communication with the server.
		const socketInstance = io(SERVER_URL);

		// Emit the event to join the room with the specific session ID
		socketInstance.emit(QrKycEvents.QR_KYC, {
			connectionId: getConnectionId,
			connectType: getConnectionType,
			type: QrKycEvents.JOIN_ROOM,
		});

		// Set up listeners for various WebSocket events
		socketInstance.on(QRKycListeners.QR_ROLE_ASSIGNED, handleListeners);
		socketInstance.on(QRKycListeners.QR_STATUS, handleListeners);
		socketInstance.on(QRKycListeners.QR_ERROR, handleListeners);
		socketInstance.on(QRKycListeners.QR_DISCONNECTED, handleListeners);

		// Cleanup function to disconnect the socket when the component unmounts
		return () => {
			socketInstance.disconnect();
		};
	}, [getConnectionId, getConnectionType, handleListeners]);

	// Function to disconnect all devices in the session
	const closeConnections = useCallback(() => {
		// Initializes a Socket.IO connection to SERVER_URL, using WebSocket or HTTP polling as fallback, enabling bidirectional communication with the server.
		const socketInstance = io(SERVER_URL);

		// Clone the current KYC QR process state to avoid mutation
		const updatedResp = structuredClone(kycQrProcess);

		// Emit an event to close the connection for the session
		socketInstance.emit(QrKycEvents.QR_KYC, {
			connectionId: updatedResp.connectionId,
			type: QrKycEvents.CONNECTION_CLOSED,
		});

		// Reset the KYC process state and disconnect the socket
		resetKycQrProcess();
		socketInstance.disconnect();
	}, [kycQrProcess, resetKycQrProcess]);

	// Function to complete the KYC process and notify the server
	const connectionCompleted = useCallback(
		(kycResponse: any) => {
			// Initializes a Socket.IO connection to SERVER_URL, using WebSocket or HTTP polling as fallback, enabling bidirectional communication with the server.
			const socketInstance = io(SERVER_URL);

			// Emit an event to mark the KYC process as complete and send the response nodes
			socketInstance.emit(QrKycEvents.QR_KYC, {
				connectionId,
				nodes: kycResponse,
				type: QrKycEvents.KYC_COMPLETE,
			});

			// Cleanup function to disconnect the socket
			return () => {
				socketInstance.disconnect();
			};
		},
		[connectionId]
	);

	// Return the functions and state necessary for managing WebSocket connections
	return {
		connectToWebSocket,
		connectionCompleted,
		kycQrProcess,
		closeConnections,
	};
};

// Custom hook to manage KYC QR process and handle QR code actions
export const useKycQr = () => {
	// Recoil state management for visibility of the KYC QR page and navigation to other states
	const setShowKycQr = useSetRecoilState(ShowKycQrPageState);
	const setQrNavigate = useSetRecoilState(KycQrPageNavigateState);
	const kycQrProcess = useRecoilValue(KycQrProcessState); // Get current KYC QR process details

	// Retrieve shared variables like host URL and session ID for QR generation
	const { hostUrl, sessionId } = useSharedVariables();

	// Retrieve session payload details from the next step (likely from a form or session state)
	const { sessionPayloadDetail, setSessionDetails } = useNextStep();

	// Get notification handler for error messages
	const { errorNotification } = useNotification();

	// WebSocket functions to manage the KYC connection process
	const { closeConnections } = useWebSocket();

	// Memoize current action to prevent unnecessary re-computations during renders
	const { currentAction } = useMemo(
		() => sessionPayloadDetail ?? {}, // Default to an empty object if sessionPayloadDetail is undefined
		[sessionPayloadDetail]
	);

	// Memoized callback to handle transitioning from QR to desktop view, closing connections as needed
	const continueWithDesktop = useCallback(() => {
		// Hide the KYC QR page
		setShowKycQr(false);
		// Close WebSocket connections
		closeConnections();
	}, [closeConnections, setShowKycQr]);

	// Memoize the QR code properties to optimize re-renders
	const qrProps = useMemo(
		() => ({
			// Construct QR code URL with dynamic session and node details
			value: `${hostUrl()}/${sessionId}?type=complex&session=complexSession&getKycQr=true&nodeId=${currentAction._id}&connectionId=${kycQrProcess?.connectionId}`,

			// QR code size
			size: 200,

			// Set error correction level (H for high correction capability)
			level: 'H',

			// Configure image settings for the logo at the center of the QR code
			imageSettings: {
				src: SimpliciLogo2, // Logo to be displayed at the center
				height: 50, // Height of the logo
				width: 50, // Width of the logo
				excavate: true, // Ensure QR content doesn't overlap the logo
			},
		}),
		[currentAction._id, hostUrl, kycQrProcess?.connectionId, sessionId] // Dependencies for recomputing qrProps
	);

	// Callback function to handle redirection based on the KYC QR process status
	const handleRedirectToNext = useCallback(() => {
		const { nodes } = kycQrProcess ?? {};
		if (nodes?._id || nodes?.stepId) {
			// Set session details if node exists
			setSessionDetails(prev => ({ ...prev, nodes }));
			// Hide KYC QR page after successful action
			setShowKycQr(false);
		} else {
			// Show error notification if something goes wrong
			errorNotification('Something went wrong.');
			// Navigate to the reload page
			setQrNavigate('qr-success-reload');
		}
	}, [
		errorNotification,
		kycQrProcess,
		setQrNavigate,
		setSessionDetails,
		setShowKycQr,
	]);

	// Return necessary functions and state to manage the KYC QR process
	return {
		continueWithDesktop, // Function to move to desktop view
		qrProps, // Properties for the QR code
		handleRedirectToNext, // Function to handle redirection after QR scanning
	};
};
