import { QRCodeSVG } from 'qrcode.react';

import { useKycQr } from '../stores';

export const QrCodeGenerator = () => {
	// Destructure `qrProps` from `useKycQr` hook to retrieve properties for generating the QR code
	const { qrProps } = useKycQr();

	return (
		<div className="Qr__Code__Generator--wrapper">
			{/* Renders the QR code as an SVG using properties from `qrProps` */}
			<QRCodeSVG {...(qrProps as { value: string })} />
		</div>
	);
};
