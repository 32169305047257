import { useKycQr } from '../../stores';
import { QrCodeGenerator } from '../qr-code-generator';

import './kyc-qr.scss';

export const KycQr = () => {
	// Destructure the `continueWithDesktop` function from `useKycQr` hook
	// This function likely handles KYC process continuation for desktop users
	const { continueWithDesktop } = useKycQr();

	return (
		<div className="Kyc__Qr--wrapper">
			{/* Wrapper for the entire QR code section */}
			<div className="Kyc__Qr--details_wrapper">
				{/* Section containing information about the KYC process */}
				<div className="Kyc__Qr--info_details">
					{/* Title for the KYC process */}
					<div className="Kyc__Qr--info_title">
						Complete identity verification on your smartphone
					</div>
					{/* Description providing details for KYC identity verification */}
					<div className="Kyc__Qr--info_description">
						Please complete identity verification on your smartphone to capture
						a high-quality photo of your government-issued ID and reduce the
						chance of errors.
					</div>
				</div>

				{/* Steps section explaining how to complete the verification */}
				<div className="Kyc__Qr--steps">
					{/* Title for the steps */}
					<div className="Kyc__Qr--steps_title">Steps</div>
					{/* Unordered list of steps for the KYC process */}
					<ul className="Kyc__Qr--steps_list">
						<li className="Kyc__Qr--steps_list_item">
							On your smartphone, open the camera and scan the QR code.
						</li>
						<li className="Kyc__Qr--steps_list_item">
							Select document issuing country.
						</li>
						<li className="Kyc__Qr--steps_list_item">
							Choose a government-Issued photo ID.
						</li>
						<li className="Kyc__Qr--steps_list_item">
							Take a photo of your government-issued ID.
						</li>
						<li className="Kyc__Qr--steps_list_item">Submit</li>
					</ul>
				</div>

				{/* Tips section providing advice for better document photos */}
				<div className="Kyc__Qr--tips">
					{/* Header containing an icon and title for the tips section */}
					<div className="Kyc__Qr--tips_header">
						<i className="ri-flashlight-fill Kyc__Qr--tips-icon" />
						<div className="Kyc__Qr--tips_title">Tips for a better photo:</div>
					</div>
					{/* Unordered list of tips to ensure better quality photos */}
					<ul className="Kyc__Qr--tips_list">
						<li className="Kyc__Qr--tips_list_item">
							Place the document in a well-lit area.
						</li>
						<li className="Kyc__Qr--tips_list_item">
							Avoid direct light sources that may cause glare.
						</li>
						<li className="Kyc__Qr--tips_list_item">
							Ensure the document is flat and not tilted.
						</li>
					</ul>
				</div>
			</div>

			{/* Section for QR code scanning */}
			<div className="Kyc__Qr--qr_wrapper">
				{/* Header text instructing the user to scan the QR code */}
				<div className="Kyc__Qr--qr_header">
					Scan the QR code using your smartphone camera.
				</div>
				{/* Wrapper for the QR image */}
				<div className="Kyc__Qr--qr_image_wrapper">
					<QrCodeGenerator />
				</div>
				{/* Footer with additional options like 'Continue using Desktop' */}
				<div className="Kyc__Qr--qr_footer">
					<div className="Kyc__Qr--qr_mobile">
						No app installation is required
					</div>
					<div className="Kyc__Qr--qr_or">OR</div>
					<div onClick={continueWithDesktop} className="Kyc__Qr--qr_desktop">
						Continue using Desktop
					</div>
				</div>
			</div>
		</div>
	);
};
