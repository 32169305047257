import { atom } from 'recoil';
import { isDesktopDevice } from 'utils/device';

// Define possible navigation states for the KYC QR page
export type IKycQrPageNavigate =
	| 'qr-page'
	| 'qr-success'
	| 'qr-success-next'
	| 'qr-loader'
	| 'qr-success-reload'
	| 'in-process'
	| 'terminated';

// Atom to control the visibility of the KYC QR page based on device type
export const ShowKycQrPageState = atom<boolean>({
	key: 'show-kyc-qr-page-state-key', // Unique key for the Recoil atom
	default: isDesktopDevice(), // Default to true if on a desktop device
});

// Atom to manage the current navigation state for the KYC QR page
export const KycQrPageNavigateState = atom<IKycQrPageNavigate>({
	key: 'kyc-qr-page-navigate-state-key', // Unique key for the Recoil atom
	default: 'qr-page', // Initial state set to 'qr-page'
});

// Interface for KYC QR process state structure
export interface IKycQrProcessState {
	role?: 'primary' | 'secondary' | null; // Device role in the KYC process
	status?: // Current status of the KYC session
	| 'qr-session-full'
		| 'secondary-connected'
		| 'primary-connected'
		| 'qr-kyc-completed'
		| 'qr-session-terminated'
		| 'secondary-disconnected'
		| null;
	message?: string; // Status or error message
	connectionId?: string; // Unique connection ID for the session
	nodes?: any | null; // Data nodes related to KYC process
}

// Atom to manage KYC QR process state globally
export const KycQrProcessState = atom<IKycQrProcessState>({
	key: 'key-qr-process-state-key', // Unique key for Recoil atom
	default: {
		// Default state values
		role: null, // No role assigned initially
		status: null, // No status assigned initially
		message: '', // Empty message by default
		connectionId: '', // No connection ID initially
	},
});
