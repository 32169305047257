// Titles and descriptions for the QR success header based on completion status
export const QrSuccessHeader = {
	close: {
		title: 'Identity verification detail successfully submitted',
		description:
			'You can now close this tab and return to your desktop to finish the rest of the onboarding process.',
		image: 'success.gif',
	},
	redirect: {
		title: 'Identity verification complete',
		description: 'Your identity verification is now complete.',
		image: 'success.gif',
	},
	reload: {
		title: 'Oops! something went wrong',
		description:
			'You can reload this page to finish the rest of the onboarding process.',
		image: 'webgl-error.svg',
	},
	'in-process': {
		title: 'Kyc already in process',
		description:
			'A KYC verification is already underway. A new KYC process cannot be started.',
		image: 'webgl-error.svg',
	},
	terminated: {
		title: 'Link Expired',
		description:
			'The QR code link has expired or the session has been terminated. Please scan the QR code again to proceed.',
		image: 'webgl-error.svg',
	},
};

// Footer message shown during the redirection phase after successful verification
export const QrSuccessFooter = {
	MESSAGE: 'Redirecting you to the next step in the onboarding process...',
};

// Loading message displayed while identity verification is in progress on the mobile device
export const QrLoading = {
	MESSAGE:
		"Your identity verification is being completed on your mobile device. Once finished, you'll be redirected automatically.",
};

// URL of the Simplici logo, used as a central image in the QR code
export const SimpliciLogo2 =
	'https://storage.googleapis.com/satschel-public-assets/images/logo/simplici-icon.png';

// Defines the different roles a user can have in the QR KYC process.
export enum QrKycUser {
	Primary = 'primary', // Primary user initiating or managing the KYC process.
	Secondary = 'secondary', // Secondary user joining the process, typically for validation or support.
}

// Specifies the WebSocket event types for the QR KYC process.
export enum QrKycEvents {
	JOIN_ROOM = 'JOINROOM', // Event to join a specific KYC room.
	KYC_COMPLETE = 'KYCCOMPLETE', // Event triggered when the KYC process is successfully completed.
	CONNECTION_CLOSED = 'CONNECTIONCLOSED', // Event for closing the connection (disconnecting from the session).
	QR_KYC = 'kyc', // General event name for KYC-related actions or messages.
}

// Lists the server listeners for specific WebSocket responses in the QR KYC process.
export enum QRKycListeners {
	QR_ROLE_ASSIGNED = 'qr-roleAssigned', // Listener for when the server assigns a role (Primary/Secondary) to the user.
	QR_STATUS = 'qr-status', // Listener for receiving KYC status updates (like process steps or current status).
	QR_ERROR = 'qr-error', // Listener for receiving error messages or failure notifications from the server.
	QR_DISCONNECTED = 'disconnected', // Listener for disconnection events from the server.
}

// Represents the possible statuses within the QR KYC session.
export enum QrKycStatus {
	KYC_COMPLETE = 'qr-kyc-completed', // Indicates the KYC process has been completed successfully.
	TERMINATED = 'qr-session-terminated', // Indicates the KYC session has been terminated (manually or due to an error).
	QR_SESSION_FULL = 'qr-session-full', // Signals that the KYC session cannot accept additional users (session is at capacity).
	SECONDARY_CONNECTED = 'secondary-connected', // Indicates that a secondary user has successfully connected to the session.
	PRIMARY_CONNECTED = 'primary-connected', // Indicates that the primary user has successfully connected to the session.
	SECONDARY_DISCONNECTED = 'secondary-disconnected', // Signals that the secondary user has disconnected from the session.
}
